<template>
  <div>
    <div class="header-container">
      <img src="@/assets/logov3.png" class="logo">
      <div class="a-group"><a href="#skills-section">Skills</a>
        <a href="#projects-section">Projects</a>
        <a href="#about-section">About</a>
        <a href="https://recipescraper.mintchococookies.com" target="_blank">Recipe Scraper</a>
      </div>
    </div>
    <main>
      <div class="intro-section fade-out">
        <div class="greeting">
          <p class="line-1">Hi there! My name is</p>
          <p class="name"><span class="underline">Lilian</span> Ung<span class="wave">👋🏼</span></p>
          <div>
            <p class="typing-erase">
              <span class="typewriter-text"></span><span class="blinking-cursor">_</span>
            </p>
          </div>
        </div>
        <br><br>
        <div class="bio-item">💻 Currently a software engineer with 1 year of
          experience<br><br>🌱 Interested in backend development,
          algorithms, machine
          learning, and robotics</div>
        <br>
        <!-- <div class="bio-list">
          <div>💻 Currently a software engineer with 1 year of experience</div>
          <div>🌱 Interested in backend development, algorithms, machine learning, and robotics</div>
        </div> -->
        <br><br>
        <div class="links">
          <a href="mailto:lilianung1@gmail.com" target="_blank"><i class="fa fa-envelope fa-2x"></i></a>
          <a href="https://www.linkedin.com/in/lilian-ung-296246239/" target="_blank"><i
              class="fab fa-linkedin-in fa-2x"></i></a>
          <a href="https://github.com/mintchococookies?tab=repositories" target="_blank"><i
              class="fab fa-github fa-2x"></i></a>
        </div>

        <div>
          <br><br><br><br>
          <i class="fa-solid fa-chevron-down" style="font-size: 1.5rem; cursor: pointer;"
            onclick="document.getElementById('skills-section').scrollIntoView({ behavior: 'smooth' });"></i>
        </div>
      </div>
      <div class="skills-section fade-out" id="skills-section">
        <p class=" section-heading">Skills and Tools</p>
        <div class="skills-list">
          <div class="skills-item">
            <p class="skills-heading"><span><i class="fa-solid fa-code"
                  style="background-color: #EF9595; padding: 15px 13px; border-radius: 50%;"></i>&nbsp;
              </span>
              <span class="skills-heading-underline">Programming Languages</span>
            </p>
            <p>Python, Java, JavaScript, HTML, CSS, PHP, Scala, PL/SQL, Bash, C++, Perl</p>
          </div>
          <div class="skills-item">
            <p class="skills-heading"><span><i class="fa-solid fa-magnifying-glass-chart"
                  style="background-color: #EF9595; padding: 15px; border-radius: 50%;"></i>&nbsp;
              </span><span class="skills-heading-underline">Data / Machine Learning</span></p>
            <p>scikit-learn, NumPy, Matplotlib, Pandas, SAS Miner, SAS Enterprise</p>
          </div>
          <div class="skills-item">
            <p class="skills-heading"><span><i class="fa-solid fa-layer-group"
                  style="background-color: #EF9595; padding: 15px 14px; border-radius: 50%;"></i>&nbsp;
              </span><span class="skills-heading-underline">DevOps</span></p>
            <p>AWS (Elastic Beanstalk, S3, Lambda, CloudWatch, RDS), Git, Bitbucket, Postman, Kubernetes, Docker</p>
          </div>
          <div class="skills-item">
            <p class="skills-heading"><span><i class="fa-solid fa-database"
                  style="background-color: #EF9595; padding: 15px 16px; border-radius: 50%;"></i>&nbsp;
              </span><span class="skills-heading-underline">Databases</span></p>
            <p>MySQL, PostgreSQL, MongoDB, AWS RDS, MariaDB, TimesTen</p>
          </div>
          <div class="skills-item">
            <p class="skills-heading"><span><i class="fa-solid fa-network-wired"
                  style="background-color: #EF9595; padding: 15px 13px; border-radius: 50%;"></i>&nbsp;
              </span><span class="skills-heading-underline">Frameworks</span></p>
            <p>FastAPI, ScalaFX, JavaFX, Akka, Kivy, Vue.js</p>
          </div>
          <div class="skills-item">
            <p class="skills-heading"><span><i class="fa-solid fa-robot"
                  style="background-color: #EF9595; padding: 15px 13px; border-radius: 50%;"></i>&nbsp;
              </span><span class="skills-heading-underline">Robotics</span></p>
            <p>Robot Operating System (ROS), Arduino</p>
          </div>
        </div>
        <div>
          <br><br><br><br>
          <i class="fa-solid fa-chevron-down" style="font-size: 1.5rem; cursor: pointer;"
            onclick="document.getElementById('projects-section').scrollIntoView({ behavior: 'smooth' });"></i>
        </div>
      </div>
      <div class="projects-section fade-out" id="projects-section">
        <p class="section-heading">Projects and Experiences</p>
        <div class="tab-buttons">
          <button @click="activeTab = 'tab1'" :class="{ 'active': activeTab === 'tab1' }">AI/Image Processing</button>
          <button @click="activeTab = 'tab2'" :class="{ 'active': activeTab === 'tab2' }">Web</button>
          <button @click="activeTab = 'tab3'" :class="{ 'active': activeTab === 'tab3' }">Games</button>
          <button @click="activeTab = 'tab4'" :class="{ 'active': activeTab === 'tab4' }">Robotics-software</button>
          <button @click="activeTab = 'tab5'" :class="{ 'active': activeTab === 'tab5' }">Hackathons/Competitions</button>
          <!-- Add more tab buttons as needed -->
        </div>

        <div v-if="activeTab === 'tab1'" class="project-list">
          <div class="project-box">
            <p class="project-heading">Heart Failure Prediction Neural Network</p>
            <div style="display: flex; justify-content: center;"><img src="@/assets/neuralnetwork2.png"
                :style="{ 'max-width': imageMaxWidth[0], 'z-index': zIndex[0] }" class="project-image"
                @click="toggleImageMaxWidth(0)" style="cursor: zoom-in;" />
            </div>
            <p class="project-description">A neural network (multilayer perceptron) to predict the
              probability of heart failure based on 11 health characteristics.</p>
            <p class="project-subheading"><span>scikit-learn</span> <span>Python</span>
              <span>NumPy</span>
              <span>Pandas</span> <span>Matplotlib</span>
            </p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/heart-failure-prediction-neural-network/tree/main"
                target="_blank">View on Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">8 Queens Puzzle Solver using Depth-first Search</p>
            <div style="display: flex; justify-content: center;"><img src="@/assets/8queens2.png"
                :style="{ 'max-width': imageMaxWidth[1], 'z-index': zIndex[1] }" class="project-image"
                @click="toggleImageMaxWidth(1)" style="cursor: zoom-in;" />
            </div>
            <p class="project-description">Allows the user to enter a number of queens (eg. 15 queens on a 15x15
              board) and generates a solution
              to place them so that they do not attack each other.</p>
            <p class="project-subheading"><span>Python</span> <span>NumPy</span> <span>Matplotlib</span></p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/8-queens/tree/main" target="_blank">View on Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">Route Planning System using Evolutionary Algorithm</p>
            <div style="display: flex; justify-content: center;">
              <img src="@/assets/routeplanning.png" :style="{ 'max-width': imageMaxWidth[2], 'z-index': zIndex[2] }"
                class="project-image" @click="toggleImageMaxWidth(2)" style="cursor: zoom-in;" />
            </div>
            <p class="project-description">Algorithm to generate the shortest path from a multistorey mall's entrance to
              visit all
              user-selected shops and leave at the nearest exit.</p>
            <p class="project-subheading"><span>Python</span> <span>Matplotlib</span></p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/ant-colony-shopping-mall-route-planner" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">Image and Text Extraction Algorithm</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/dilation_output.png"
                  :style="{ 'max-width': imageMaxWidth[3], 'z-index': zIndex[3] }" class="project-image"
                  @click="toggleImageMaxWidth(3)" style="cursor: zoom-in;" />
                <p class="image-caption">Binary mask obtained through erosion and dilation</p>
              </div>
              <div class="project-images-item"><img src="@/assets/drawing.png"
                  :style="{ 'max-width': imageMaxWidth[4], 'z-index': zIndex[4] }" class="project-image"
                  @click="toggleImageMaxWidth(4)" style="cursor: zoom-in;" />
                <p class="image-caption">Extracted image (inversed)</p>
              </div>
              <div class="project-images-item"><img src="@/assets/table20.png"
                  :style="{ 'max-width': imageMaxWidth[5], 'z-index': zIndex[5] }" class="project-image"
                  @click="toggleImageMaxWidth(5)" style="cursor: zoom-in;" />
                <p class="image-caption">Extracted table</p>
              </div>
            </div>
            <p class="project-description">Program that uses OpenCV to extract randomly shaped drawings into image files
              and Pytesseract Optical Character Recognition to extract tabular data into Excel files.
              Achieved 97.67% accuracy on a dataset of 40 images.</p>
            <p class="project-subheading"><span>OpenCV</span> <span>NumPy</span> <span>Pytesseract</span>
              <span>Python</span>
            </p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/image-and-text-extraction" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
        </div>
        <div v-if="activeTab === 'tab2'" class="project-list">
          <div class="large-box project-box">
            <p class="project-heading">Recipe Scraper Web App</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/recipe-scraper1.png"
                  :style="{ 'max-width': imageMaxWidth[6], 'z-index': zIndex[6] }" class="project-image"
                  @click="toggleImageMaxWidth(6)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/recipe-scraper2.png"
                  :style="{ 'max-width': imageMaxWidth[7], 'z-index': zIndex[7] }" class="project-image"
                  @click="toggleImageMaxWidth(7)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/recipe-scraper3.png"
                  :style="{ 'max-width': imageMaxWidth[8], 'z-index': zIndex[8] }" class="project-image"
                  @click="toggleImageMaxWidth(8)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">A fullstack recipe scraper tool that extracts ingredients and steps from any
              recipe URL on the internet, with features for adjusting servings, converting units, and saving or printing
              the recipe in a clean format.</p>
            <p class="project-subheading"><span>Flask API</span> <span>Vue.js</span> <span>BeautifulSoup</span>
              <span>JWT</span> <span>Pytest</span>
            </p>
            <div style="display:flex; justify-content:center; gap: 0.5rem;" class="project-subheading"><a
                href="https://github.com/mintchococookies/recipe-scraper-backend" target="_blank"
                style="text-align:center;">View Backend
                on
                Github
                <i class=" fa-solid fa-arrow-up-right-from-square"></i></a><a
                href="https://github.com/mintchococookies/recipe-scraper-frontend" target="_blank"
                style="text-align:center;">View
                Frontend on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a><a
                href="https://signifymsl.github.io/signify/index.html" target="_blank" style="text-align:center;">Website
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">E-commerce Website</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/ecomm1.png"
                  :style="{ 'max-width': imageMaxWidth[9], 'z-index': zIndex[9] }" class="project-image"
                  @click="toggleImageMaxWidth(9)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">An online store for a bakery hosted on an Apache web server, allowing users to
              browse products and log in to their accounts to place orders.</p>
            <p class="project-subheading"><span>HTML</span> <span>CSS</span> <span>JavaScript</span>
              <span>PHP</span> <span>Apache</span> <span>MySQL</span>
            </p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/ecommerce-website" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">Educational Website for Malaysian Sign Language</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/signify.png"
                  :style="{ 'max-width': imageMaxWidth[10], 'z-index': zIndex[10] }" class="project-image"
                  @click="toggleImageMaxWidth(10)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">A website to learn Malaysian Sign Language (MSL) through a searchable video
              dictionary and
              games.</p>
            <p class="project-subheading"><span>HTML</span> <span>CSS</span> <span>JavaScript</span>
              <span>JQuery</span>
            </p>
            <div style="display:flex; justify-content:center; gap: 0.5rem;" class="project-subheading"><a
                href="https://github.com/mintchococookies/signify" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a><a
                href="https://signifymsl.github.io/signify/index.html" target="_blank">Website
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
        </div>
        <!-- Add more project lists for other tabs -->
        <div v-if="activeTab === 'tab3'" class="project-list">
          <div class="large-box project-box">
            <p class="project-heading">Online Multiplayer Monopoly</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/monopoly1.png"
                  :style="{ 'max-width': imageMaxWidth[11], 'z-index': zIndex[11] }" class="project-image"
                  @click="toggleImageMaxWidth(11)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/monopoly2.png"
                  :style="{ 'max-width': imageMaxWidth[12], 'z-index': zIndex[12] }" class="project-image"
                  @click="toggleImageMaxWidth(12)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">Client-server Java desktop game using the MVC Controller architecture. Users
              can
              roll dice, buy land and houses, pay rent, go to jail, draw chance cards and more!</p>
            <p class="project-subheading"><span>Scala</span> <span>Java</span> <span>Akka</span> <span>ScalaFX</span>
              <span>JavaFX</span> <span>Json</span>
            </p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/multiplayer-monopoly" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">Boat Racing</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/boatracing2.png"
                  :style="{ 'max-width': imageMaxWidth[13], 'z-index': zIndex[13] }" class="project-image"
                  @click="toggleImageMaxWidth(13)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/boatracing1.png"
                  :style="{ 'max-width': imageMaxWidth[14], 'z-index': zIndex[14] }" class="project-image"
                  @click="toggleImageMaxWidth(14)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">A CLI-based variation of the Snake and Ladders game written in Java OOP.
              Includes 3
              difficulty levels and scoreboards.</p>
            <p class="project-subheading"><span>Java</span></p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/boat-racing" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">Mastermind</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/mastermind.png"
                  :style="{ 'max-width': imageMaxWidth[15], 'z-index': zIndex[15] }" class="project-image"
                  @click="toggleImageMaxWidth(15)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">The classic Mastermind game implemented in Python CLI.</p>
            <p class="project-subheading"><span>Python</span></p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://github.com/mintchococookies/mastermind" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
        </div>
        <div v-if="activeTab === 'tab4'" class="project-list">
          <div class="project-box">
            <p class="project-heading">FYP: 3D Obstacle Detection and Avoidance Autonomous Robotic Navigation System</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/obstacle.png"
                  :style="{ 'max-width': imageMaxWidth[16], 'z-index': zIndex[16] }" class="project-image"
                  @click="toggleImageMaxWidth(16)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">A 3D image-based obstacle detection and avoidance navigation system optimized
              for robots operating in narrow environments with densely packed obstacles.</p>
            <p class="project-subheading"><span>Robot Operating System (ROS)</span> <span>Python</span>
              <span>Ubuntu</span>
            </p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                style="text-align:center;">View <i class="fa-solid fa-arrow-up-right-from-square"></i><br> Coming Soon
                (Accepted into IEEE Control
                Systems, Computing and Engineering Conference)
              </a></div>
          </div>
          <div class="project-box">
            <p class="project-heading">ROS Study Notes</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/ros.png"
                  :style="{ 'max-width': imageMaxWidth[17], 'z-index': zIndex[17] }" class="project-image"
                  @click="toggleImageMaxWidth(17)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">A small webpage compilation of my notes whilst studying the Robot Operating
              System :)
            </p>
            <p class="project-subheading"><span>HTML</span> </p>
            <div style="display:flex; justify-content:center; gap: 0.5rem;" class="project-subheading"><a
                href="https://github.com/mintchococookies/ROS-Tutorials" target="_blank">View on
                Github
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a><a
                href="https://mintchococookies.github.io/ROS-Tutorials/" target="_blank">Website
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="large-box project-box">
            <p class="project-heading">Graphical User Interface for an Autonomous Robot</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/gui2.png"
                  :style="{ 'max-width': imageMaxWidth[18], 'z-index': zIndex[18] }" class="project-image"
                  @click="toggleImageMaxWidth(18)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/library-robot.png"
                  :style="{ 'max-width': imageMaxWidth[19], 'z-index': zIndex[19] }" class="project-image"
                  @click="toggleImageMaxWidth(19)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">Python-based GUI to send commands to a robot's backend systems for an industry
              robot development project.</p>
            <p class="project-subheading"><span>Robot Operating System (ROS)</span> <span>Python</span> <span>Kivy</span>
            </p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://sunwayuniversity.edu.my/news/2023/sunway-library-enters-a-new-chapter-in-technology-with-a-high-tech-shelf-reading-robot"
                target="_blank">View News Article
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
        </div>
        <div v-if="activeTab === 'tab5'" class="project-list">
          <div class="large-box project-box">
            <p class="project-heading">Varsity Hackathon 2023 Champion</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/vhack1.png"
                  :style="{ 'max-width': imageMaxWidth[20], 'z-index': zIndex[20] }" class="project-image"
                  @click="toggleImageMaxWidth(20)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/vhack2.png"
                  :style="{ 'max-width': imageMaxWidth[21], 'z-index': zIndex[21] }" class="project-image"
                  @click="toggleImageMaxWidth(21)" style="cursor: zoom-in;" />
              </div>
            </div>
            <br>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/vhack6.png"
                  :style="{ 'max-width': imageMaxWidth[22], 'z-index': zIndex[22] }" class="project-image"
                  @click="toggleImageMaxWidth(22)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/vhack7.png"
                  :style="{ 'max-width': imageMaxWidth[23], 'z-index': zIndex[23] }" class="project-image"
                  @click="toggleImageMaxWidth(23)" style="cursor: zoom-in;" />
              </div>
            </div>
            <br>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/vhack4.png"
                  :style="{ 'max-width': imageMaxWidth[24], 'z-index': zIndex[24] }" class="project-image"
                  @click="toggleImageMaxWidth(24)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/vhack5.png"
                  :style="{ 'max-width': imageMaxWidth[25], 'z-index': zIndex[25] }" class="project-image"
                  @click="toggleImageMaxWidth(25)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">Emerged as the champion among 410 teams from 20 countries with a VR Job
              Simulation solution that empowers adolescents to make better-informed career choices through practical job
              experiences.</p>
            <div style="display:flex; justify-content:center;" class="project-subheading"><a
                href="https://vhackusm.com/winners" target="_blank">View Winner List (Competition Website)
                <i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
          </div>
          <div class="large-box project-box">
            <p class="project-heading">Make It Challenge 2022 Finalist</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/mic.png"
                  :style="{ 'max-width': imageMaxWidth[26], 'z-index': zIndex[26] }" class="project-image"
                  @click="toggleImageMaxWidth(26)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/mic2.png"
                  :style="{ 'max-width': imageMaxWidth[27], 'z-index': zIndex[27] }" class="project-image"
                  @click="toggleImageMaxWidth(27)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">Selected as one of 8 finalists with a computer vision-based automatic waste
              segregation bin solution for environmental sustainability.</p>
          </div>
          <div class="large-box project-box">
            <p class="project-heading">Robocon Malaysia 2022 & 2023</p>
            <div class="project-images">
              <div class="project-images-item"><img src="@/assets/robocon232.png"
                  :style="{ 'max-width': imageMaxWidth[28], 'z-index': zIndex[28] }" class="project-image"
                  @click="toggleImageMaxWidth(28)" style="cursor: zoom-in;" />
              </div>
              <div class="project-images-item"><img src="@/assets/robocon22.png"
                  :style="{ 'max-width': imageMaxWidth[29], 'z-index': zIndex[29] }" class="project-image"
                  @click="toggleImageMaxWidth(29)" style="cursor: zoom-in;" />
              </div>
            </div>
            <p class="project-description">Programmed the movement and functionality of remote-controlled competitive
              robots using C++ on the Arduino microcontroller.</p>
          </div>
        </div>

        <div>
          <br><br><br><br>
          <i class="fa-solid fa-chevron-down" style="font-size: 1.5rem; cursor: pointer;"
            onclick="document.getElementById('about-section').scrollIntoView({ behavior: 'smooth' });"></i>
        </div>
      </div>
      <div class="about-section" id="about-section">
        <p class="section-heading">About</p>
        <p class="about-description">Hi there! 😄 I'm Lilian, a software engineer passionate about learning and
          problem-solving. Before graduating in late 2023, I gained valuable experience through a software engineering
          internship focused on backend and API development for an AI-based app.
          <br><br>
          I also pursued my passion for robotics, which started with Lego robotics competitions in primary school. Over
          the
          past two years, I collaborated on developing a <a
            href="https://sunwayuniversity.edu.my/news/2023/sunway-library-enters-a-new-chapter-in-technology-with-a-high-tech-shelf-reading-robot"
            target="_blank">library bookshelf scanning robot</a> for my university's library, which involved designing its
          GUI and implementing an obstacle detection and avoidance system for my final year project.
          <br><br>
          These experiences have deepened my interest in software, backend, and algorithm development, motivating me to
          pursue a career as a software engineer. 😄 Check out my latest full-stack project, a <a
            href="YOUR_PROJECT_URL_HERE" target="_blank">recipe scraper</a>, developed using Flask and Vue.js.
          <br><br>
          Please feel free to connect via <a href="https://www.linkedin.com/in/lilian-ung-296246239/"
            target="_blank">LinkedIn</a> for my resume and latest work experience.
        </p>
        <!-- <div class="contact-box">
          <div class="contact-item">
            <p class="contact-heading">Lets connect! I would love to contribute to meaningful projects 🙌</p>
            <p class="contact-details"><i class="fab fa-linkedin-in fa-2x"></i>&nbsp; <a
                href="https://www.linkedin.com/in/lilian-ung-296246239/">Lilian Ung</a></p>
            <p class="contact-details"><i class="fa fa-envelope fa-2x"></i>&nbsp; lilianung1@gmail.com</p>
          </div>
          <div class="contact-item">
            <p class="contact-heading-2">Drop me a message</p>
            <form name="contact" method="POST" netlify>
              <input type="hidden" name="form-name" value="contact" />
              <label for="email" class="contact-details" style="font-weight: 600;">Email: </label>
              <br>
              <input type="email" id="email" name="email" v-model="email" required>
              <br><br>
              <label for="msg" class="contact-details" style="font-weight: 600;">Message: </label>
              <br>
              <textarea id="msg" name="msg" rows="5" required></textarea>
              <br><br>
              <div style="display: flex; justify-content: center;">
                <button type="submit" class="send-btn">Send</button>
              </div>
            </form>
          </div>
        </div> -->
      </div>
      <!-- Scroll to top button -->
      <button class="scroll-to-top" @click="scrollToTop" :class="{ show: showScrollToTop }">
        <i class="fa-solid fa-chevron-up"></i>
      </button>
      <div class="footer">
        <p>Created by Lilian using Vue.js | 2024 | Logo generated with Bing AI</p>
      </div>
    </main>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;600&family=Poppins:wght@500;700&display=swap');

@import '~@fortawesome/fontawesome-free/css/all.css';

body {
  font-family: 'JetBrains Mono', monospace;
  background-color: #0A1828;
  color: #fff;
}

main {
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  align-items: center;
}

.project-images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-images-item {
  text-align: center;
  font-style: italic;
  display: flex;
  flex-direction: column;
}

.image-caption {
  font-size: 0.85rem;
}

.about-description>a {
  color: #AFEEEE
}

input,
textarea {
  padding: 10px 20px;
  border-radius: 10px;
  font-family: 'JetBrains Mono', monospace;
  width: 90%;
  margin-top: 5px;
}

.send-btn {
  padding: 10px 20px;
  /* Add padding for spacing */
  border-radius: 5px;
  text-align: center;
}

.contact-box {
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 10px;
  width: 43%;
  color: black;
  padding: 10px 40px 30px 40px;
  margin-top: 55px;
  justify-content: center;
}

.contact-heading,
.contact-heading-2 {
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.contact-heading-2 {
  font-size: 1.3rem;
}

.contact-details {
  font-size: 0.9rem;
}

.contact-details>i {
  font-size: 1rem;
  color: #3A3B3C;
}

.contact-box div:nth-child(1) {
  width: 40%;
  margin-right: 100px;
}

.contact-box div:nth-child(2) {
  width: 50%;
}

.links a {
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
}

/* Hover effect for the link icons */
.links a:hover {
  transform: scale(1.2);
  /* Scale the icon to 1.2 times its original size */
}

.thumbnail span {
  /*CSS for enlarged image*/
  padding: 5px;
  visibility: hidden;
}

.thumbnail {
  cursor: zoom-in;
}

.thumbnail span img {
  position: fixed;
  max-width: 60%;
  left: 20%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  /* Add a semi-transparent background */
  z-index: 999;
  /* Ensure it's on top of other content */
}

.thumbnail:focus span {
  visibility: visible;
}

.header-container a {
  color: #fff;
  margin-left: 2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
}

@media(max-width: 375px) {
  body {
    width: 100%;
  }
}

@media(min-width: 800px) {
  .project-list {
    width: 55%;
  }

  .skills-list {
    width: 69%;
  }
}

@media(max-width: 768px) {

  .greeting {
    margin-top: 20px;
  }

  /* .header-container .a-group {
    position: fixed;
    z-index: 1000;
  } */
}

.header-container a {
  text-decoration: none;
  color: inherit;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.2s, border-radius 0.2s;
  font-size: 1rem;
}

.header-container a:hover {
  border-radius: 10px;
}

.header-container a:nth-child(1):hover {
  background-color: rgb(239, 149, 149, 0.5);
}

.header-container a:nth-child(2):hover {
  background-color: rgb(149, 239, 149, 0.5);
}

.header-container a:nth-child(3):hover {
  background-color: rgb(149, 149, 239, 0.5);
}

.header-container a:nth-child(4):hover {
  background-color: rgb(191, 161, 129, 0.5);
}

.intro-section,
.projects-section,
.about-section,
.skills-section {
  margin-top: 1.2rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-image {
  align-self: center;
  border-radius: 10px;
}

.greeting {
  text-align: center;
  padding: 10px;
  border: 2px solid white;
  border-radius: 10px;
  width: 100%;
}

.section-heading {
  font-size: 2.5rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.2rem;
  font-weight: 600;
  text-align: center;
}

.about-description {
  width: 45%;
  text-align: justify;
}

.header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 30px;
}

.logo {
  height: 70px;
  margin-right: auto;
}

.skills-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.skills-item {
  background-color: white;
  /* border: 2px solid #f2f2f2; */
  border-radius: 10px;
  padding: 5px 12px 5px 12px;
  text-align: center;
  font-size: 0.9rem;
  width: calc(33.33%);
  color: black;
}

.skills-heading,
.project-heading {
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}

.skills-heading-underline {
  text-decoration: underline;
}

.line-1 {
  font-size: 1.2rem;
  color: white;
}

.project-subheading {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 2;
}

.project-subheading>span {
  background: #BC7AF9;
  padding: 3px 6px 3px 6px;
  border-radius: 5px;
  margin-right: 10px;
  color: white;
}

.project-subheading>a {
  background: white;
  padding: 0px 6px 0px 6px;
  border-radius: 5px;
  border: 2px solid #BC7AF9;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: none;
}

.name {
  font-size: 4rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.2rem;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #BFA181;
}

.typewriter-text {
  font-size: 1.2rem;
  color: white;
  font-style: italic;
}

.typing-erase {
  font-size: 1.2rem;
}

.blinking-cursor {
  animation: blink-animation 0.8s infinite;
}

@keyframes blink-animation {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.project-description {
  font-size: 0.9rem;
}

.bio-list {
  font-size: 1rem;
  display: flex;
  max-width: 50rem;
}

.bio-list>div {
  flex: 1;
  padding: 15px;
  margin: 0px 10px 10px 10px;
  border-radius: 8px;
  background-color: rgba(191, 161, 129, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  animation: fade-up-animation 2s forwards;
}

@keyframes fade-up-animation {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.links {
  display: flex;
  gap: 1.5rem;
}

button {
  font-family: 'JetBrains Mono', monospace;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  20% {
    transform: rotate(-10.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-10.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}

.footer>p {
  font-size: 0.75rem;
  bottom: 0;
  text-align: center;
}

.tab-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.tab-buttons button {
  background-color: transparent;
  border: 2px solid #AFEEEE;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}

.tab-buttons button.active {
  background-color: #AFEEEE;
  color: black;
  border-radius: 8px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 50px;
  justify-content: center;
  /* width: 64%; */
  /* width: 55%; */
  /*change this to make the section of boxes wider on the left and right */
}

.project-box {
  /* flex: 1 1 calc(33.33% - 20px); */
  /* Three boxes per row */
  background-color: #f2f2f2;
  padding: 15px 20px 28px 20px;
  border-radius: 8px;
  color: black;
  max-width: 40%;
  min-width: 15%;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  background-color: #BFA181;
  border: none;
  border-radius: 50%;
  padding: 10px 13px;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: scale(0);
  font-size: 1.2rem;
  z-index: 9999;
}

.scroll-to-top.show {
  opacity: 1;
  transform: scale(1);
}

.large-box {
  max-width: 90%;
}

.bio-item {
  max-width: 80%;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}


@media (max-width: 480px) {

  .scroll-to-top {
    z-index: 9999;
  }

  .greeting {
    width: 95%;
  }

  .footer>p {
    font-size: 0.65rem;
    bottom: 0;
    text-align: center;
  }

  .skills-section {
    padding: 0px;
  }

  .bio-item {
    font-size: 0.9rem;
    max-width: 95%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .skills-list,
  .about-description {
    width: 100%;
  }

  .large-box {
    max-width: 100%;
  }

  .project-images {
    display: flex;
    flex-direction: column;
  }

  .header-container>img {
    display: none;
  }

  .header-container a {
    margin-left: 0;
    align-items: center;
    font-size: 0.8rem;
    padding: 0px;
  }

  .header-container {
    margin-right: 0px;
    padding: 20px 20px;
  }

  .a-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  input,
  textarea {
    width: 84%;
  }

  .contact-box div:nth-child(2) {
    width: 100%;
  }

  .contact-details {
    font-size: 0.8rem;
  }

  .about-description {
    font-size: 0.8rem;
  }

  .contact-heading-2 {
    font-size: 1rem;
  }

  .contact-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px;
    font-size: 0.9rem;
  }

  .contact-heading {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .contact-box div:nth-child(1) {
    width: 100%;
  }

  .line-1 {
    font-size: 1rem;
  }

  .name {
    font-size: 3rem;
    margin: 5px;
  }

  .project-box {
    max-width: 100%;
  }

  .typewriter-text {
    font-size: 1rem;
  }

  .bio-list {
    font-size: 0.9rem;
    display: flex;
    max-width: 50rem;
  }

  .section-heading {
    font-size: 2rem;
  }

  .skills-item {
    font-size: 0.8rem;
  }

  .skills-heading,
  .project-heading {
    font-size: 0.9rem;
  }

  .project-list {
    font-size: 0.8rem;
    max-width: 92%;
  }

  .projects-section {
    font-size: 0.8rem;
  }

  .project-description,
  .project-subheading,
  .project-subheading>a {
    font-size: 0.8rem;
  }

  .tab-buttons button {
    font-size: 0.9rem;
  }

}
</style>


<script>
export default {
  mounted() {
    const text = "Software engineer and hobbyist robotics programmer.";
    const typewriterText = document.querySelector(".typewriter-text");
    const blinkingCursor = document.querySelector(".blinking-cursor");

    function typeText(index) {
      const typewriterTextRect = typewriterText.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (index < text.length && typewriterTextRect.top < windowHeight && typewriterTextRect.bottom > 0) {
        typewriterText.textContent += text.charAt(index);
        setTimeout(() => {
          typeText(index + 1);
        }, 80); // Adjust typing speed here
      } else {
        setTimeout(() => {
          eraseText(typewriterText.textContent.length - 1);
        }, 2000); // Delay before erasing
      }
    }

    function eraseText(index) {
      if (index >= 0) {
        typewriterText.textContent = typewriterText.textContent.slice(0, index);
        setTimeout(() => {
          eraseText(index - 1);
        }, 20); // Adjust erasing speed here
      } else {
        setTimeout(() => {
          typeText(0);
        }, 500); // Delay before typing again
      }
    }

    typeText(0);

    setInterval(() => {
      blinkingCursor.style.opacity = blinkingCursor.style.opacity === "0" ? "1" : "0";
    }, 500); // Blinking cursor animation

    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Call it once to apply the initial fade classes
    window.addEventListener('resize', this.handleScroll);
    window.addEventListener('scroll', this.toggleScrollToTop);
  },
  data() {
    return {
      activeTab: 'tab1', // Default active tab
      scrollPosition: 0,
      showScrollToTop: false,
      email: '',
      emailError: '',
      imageMaxWidth: Array(40).fill('95%'),
      zIndex: Array(40).fill('9000'),
    };
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
    window.removeEventListener('scroll', this.toggleScrollToTop);
  },
  methods: {
    handleScroll() {
      const introSection = document.querySelector('.intro-section');
      const projectSection = document.querySelector('.projects-section');
      const aboutSection = document.querySelector('.about-section');
      const skillsSection = document.querySelector('.skills-section');

      const introSectionTop = introSection.getBoundingClientRect().top;
      const projectSectionTop = projectSection.getBoundingClientRect().top;
      const aboutSectionTop = aboutSection.getBoundingClientRect().top;
      const skillsSectionTop = skillsSection.getBoundingClientRect().top;

      const windowHeight = window.innerHeight;

      // Calculate the threshold value for fade-in
      const fadeInThreshold = windowHeight * 0.7;

      // Check if intro section is in view
      if (introSectionTop < fadeInThreshold) {
        introSection.classList.add('fade-in');
        introSection.classList.remove('fade-out');
      } else {
        introSection.classList.remove('fade-in');
        introSection.classList.add('fade-out');
      }

      // Check if project section is in view
      if (projectSectionTop < fadeInThreshold) {
        projectSection.classList.add('fade-in');
        projectSection.classList.remove('fade-out');
      } else {
        projectSection.classList.remove('fade-in');
        projectSection.classList.add('fade-out');
      }

      // Check if skills section is in view
      if (skillsSectionTop < fadeInThreshold) {
        skillsSection.classList.add('fade-in');
        skillsSection.classList.remove('fade-out');
      } else {
        skillsSection.classList.remove('fade-in');
        skillsSection.classList.add('fade-out');
      }

      // Check if about section is in view
      if (aboutSectionTop < fadeInThreshold) {
        aboutSection.classList.add('fade-in');
        aboutSection.classList.remove('fade-out');
      } else {
        aboutSection.classList.remove('fade-in');
        aboutSection.classList.add('fade-out');
      }
    },
    toggleScrollToTop() {
      this.showScrollToTop = window.scrollY > 300; // Show the button after scrolling down 500px
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    toggleImageMaxWidth(index) {
      this.imageMaxWidth[index] = this.imageMaxWidth[index] === '200%' ? '95%' : '200%';
      this.zIndex[index] = this.zIndex[index] === '9999' ? '0' : '9999';

      // Reset the max-width for all other images
      for (let i = 0; i < this.imageMaxWidth.length; i++) {
        if (i !== index) {
          this.imageMaxWidth[i] = '95%';
          this.zIndex[i] = '0';
        }
      }
    },
  },
};
</script>
